<template>
  <div
    class="skimovie"
  >
    <template v-if="(runs && runs.length > 0) || (schoolRuns && schoolRuns.length > 0)">
      <div
        id="nav-tab"
        class="nav nav-tabs"
        role="tablist"
      >
        <template
          v-if="runs && runs.length > 0"
        >
          <a
            v-for="(key, index) in runs"
            :id="`spoT${ index }-tab`"
            :key="`skimovie-run-spot-tab-listItem-${ index }`"
            :class="['nav-item nav-link', {'active': index == 0}]"
            data-toggle="tab"
            :href="`#spoT${ index }`"
            role="tab"
            :aria-controls="`spoT${ index }`"
            :aria-selected="index === 0"
          >Spot: {{ key[0] }}</a>
        </template>
        <template
          v-if="schoolRuns && schoolRuns.length > 0"
        >
          <a
            v-for="(key, index) in schoolRuns"
            :id="`schoolSpoT${ index }-tab`"
            :key="`skimovie-school-spot-tab-listItem-${ index }`"
            :class="['nav-item nav-link', {'active': !(runs && runs.length > 0) && index == 0}]"
            data-toggle="tab"
            :href="`#schoolSpoT${ index }`"
            role="tab"
            :aria-controls="`schoolSpoT${ index }`"
            :aria-selected="!(runs && runs.length > 0) && index == 0"
          >Skischool Spot: {{ key[0] }}</a>
        </template>
      </div>
      <div
        id="nav-tabContent"
        class="tab-content"
      >
        <template
          v-if="runs && runs.length > 0"
        >
          <div
            v-for="(vals, index) in runs"
            :id="`spoT${ index }`"
            :key="`skimovie-run-session-listItem-${ index }`"
            :class="['tab-pane fade show', {'active': index == 0}]"
            role="tabpanel"
            :aria-labelledby="`spoT${ index }-tab`"
          >
            <SessionOverview
              :runs="vals[1]"
              :display-properties="['duration', 'createDate', 'card']"
              :loading="loading"
              :filter="filter"
              :installation-type="installationType"
              :installation-id="installationId"
              @loadSessions="loadSessions"
            />
          </div>
        </template>
        <template
          v-if="schoolRuns && schoolRuns.length > 0"
        >
          <div
            v-for="(vals, index) in schoolRuns"
            :id="`schoolSpoT${ index }`"
            :key="`skimovie-school-session-listItem-${ index }`"
            :class="['tab-pane fade show', {'active': !(runs && runs.length > 0) && index == 0}]"
            role="tabpanel"
            :aria-labelledby="`schoolSpoT${ index }-tab`"
          >
            <SessionOverview
              :runs="vals[1]"
              :display-properties="['duration', 'createDate', 'card']"
              :loading="loading"
              :filter="filter"
              :installation-type="installationType"
              :installation-id="installationId"
              @loadSessions="loadSessions"
            />
          </div>
        </template>
      </div>
    </template>

    <p v-else-if="preparing == false && (runs == null || (runs != null && runs.length == 0)) && (schoolRuns == null || (schoolRuns != null && schoolRuns.length == 0))">
      No data available.
    </p>

    <LoadingPlaceholder v-else />
    <!-- :style="`width: ${ computedWidth }%;`" -->
    <SweetModal
      ref="skimovieModal"
      title="<span>Session</span>"
      @close="closeModal()"
    >
      <template
        v-if="(altMobileVideoUrl != null && altMobileVideoUrl.length > 0) && (altVideoUrl != null && altVideoUrl.length > 0)"
        slot="box-action"
      >
        <label class="mb-4 pr-2">{{ $t('quality') }}:</label>
        <toggle-button
          v-model="mobileIsActive"
          :sync="true"
          :labels="{ checked: 'mobile', unchecked: 'HD' }"
          :color="{ checked: 'rgb(117, 199, 145)', unchecked: 'rgb(117, 170, 199)' }"
          :width="90"
          :height="30"
          :font-size="12"
          class="m-0"
          @input="sourceIt()"
        />
      </template>
    

      <sweet-modal-tab
        id="tab1"
        title="Player"
      >
        <video-player
          v-if="playerOptions.sources[0] != null"
          id="videoPlayerOne"
          ref="videoRef"
          :playsinline="true"
          :options="playerOptions"
        />
      </sweet-modal-tab>

      <sweet-modal-tab
        id="tab2"
        title="Details"
      >
        <table class="defaultTable">
          <colgroup>
            <col width="200">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>Mobile video url</th>
              <td class="lineBreaks">
                {{ altMobileVideoUrl }}
              </td>
            </tr>
            <tr>
              <th>HD video url</th>
              <td class="lineBreaks">
                {{ altVideoUrl }}
              </td>
            </tr>
            <tr>
              <th>Run id</th>
              <td>{{ runId }}</td>
            </tr>
            <tr>
              <th>Create date</th>
              <td v-tooltip="dateTime_fromNow(runDate)">
                {{ dateTime_dateTime(runDate) }}
              </td>
            </tr>
            <tr>
              <th>Card number</th>
              <td>{{ cardNumbers }}</td>
            </tr>
            <tr>
              <th>RaceStarted</th>
              <td>{{ raceStarted }}</td>
            </tr>
            <tr>
              <th>SessionFinished</th>
              <td>{{ raceFinished }}</td>
            </tr>
            <tr>
              <th>ObjectCaught</th>
              <td>{{ objectCaught }}</td>
            </tr>
            <tr>
              <th>ObjectLost</th>
              <td>{{ objectLost }}</td>
            </tr>
            <tr>
              <th>Timeout</th>
              <td>{{ timeout }}</td>
            </tr>
            <tr>
              <th>IsFaulty</th>
              <td>{{ isFaulty }}</td>
            </tr>
            <tr>
              <th>TimingMode</th>
              <td>{{ timingMode }}</td>
            </tr>
            <tr>
              <th>VirtualStopwatch</th>
              <td>{{ virtualStopwatch }}</td>
            </tr>
          </tbody>
        </table>
      </sweet-modal-tab>
    </SweetModal>

    <SweetModal
      ref="detailsModal"
      title="<span>Session details</span>"
      @close="closeModal()"
    >
      <table class="defaultTable">
        <colgroup>
          <col width="200">
          <col>
        </colgroup>
        <tbody>
          <tr>
            <th>Mobile video url</th>
            <td class="lineBreaks">
              {{ altMobileVideoUrl }}
            </td>
          </tr>
          <tr>
            <th>HD video url</th>
            <td class="lineBreaks">
              {{ altVideoUrl }}
            </td>
          </tr>
          <tr>
            <th>Run id</th>
            <td>{{ runId }}</td>
          </tr>
          <tr>
            <th>Create date</th>
            <td v-tooltip="dateTime_fromNow(runDate)">
              {{ dateTime_dateTime(runDate) }}
            </td>
          </tr>
          <tr>
            <th>Card number</th>
            <td>{{ cardNumbers }}</td>
          </tr>
          <tr>
            <th>RaceStarted</th>
            <td>{{ raceStarted }}</td>
          </tr>
          <tr>
            <th>SessionFinished</th>
            <td>{{ raceFinished }}</td>
          </tr>
          <tr>
            <th>ObjectCaught</th>
            <td>{{ objectCaught }}</td>
          </tr>
          <tr>
            <th>ObjectLost</th>
            <td>{{ objectLost }}</td>
          </tr>
          <tr>
            <th>Timeout</th>
            <td>{{ timeout }}</td>
          </tr>
          <tr>
            <th>IsFaulty</th>
            <td>{{ isFaulty }}</td>
          </tr>
          <tr>
            <th>TimingMode</th>
            <td>{{ timingMode }}</td>
          </tr>
        </tbody>
      </table>
    </SweetModal>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';

import { videoPlayer } from 'vue-video-player';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { mobileMixin } from '@/mixins/mobileMixin.js';
import { SweetModal, SweetModalTab } from 'sweet-modal-vue';

export default {
  name: "Skimovie",
  components: {
    SessionOverview: () => import('@/components/Media/SessionOverview.vue'),
    SweetModal,
    SweetModalTab,
    videoPlayer
  },
  mixins: [
    dateTimeMixin,
    mobileMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    installationType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      useAlternativeFilter: true,
      mobileIsActive: false,
      loading: true,
      runs: null,
      schoolRuns: null,
      altSnapshotUrl: null,
      altVideoUrl: null,
      altMobileVideoUrl: null,
      raceStarted: null,
      raceFinished: null,
      objectCaught: null,
      objectLost: null,
      timingMode: null,
      virtualStopwatch: null,
      isFaulty: null,
      timeout: null,
      modalId: "skimovieModal",
      runDate: null,
      runId: null,
      filter: {
        dates: null,
        cardNumber: null,
        startDate: null,
        finalState: null,
        endDate: null,
        showIncomplete: true,
        limit: 100,
        raceStarted: false,
        raceFinished: false,
        objectCaught: false,
        objectLost: false,
        timeout: false,
        isFaulty: false,
        timingMode: false
      },
      preparing: true,
      cardNumbers: null,
      computedWidth: 50,
      playerOptions: {
        autoplay: true,
        responsive: true,
        muted: false,
        preload: true,
        language: 'en',
        playbackRates: [0.25, 0.5, 1, 1.5, 2],
        sources: [null],
        poster: null,
        fluid: true //this.mobileMixin_isSmallScreen
      }
    }
  },
  created () {
    let tmpDate = new Date(Date.now());
    this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 0, 0, 0);
    this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), 23, 59, 59);
    this.filter.dates = [];
    this.filter.dates.push(this.filter.startDate, this.filter.endDate);

    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    this.$eventBus.$on(`openModal${this.modalId}`, this.openModal);
    this.loadSessions();
  },
  beforeDestroy () {
    this.$eventBus.$off(`openModal${this.modalId}`, this.openModal);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
//#region Helpers
    handleResize () {
      this.playerOptions.fluid = this.mobileMixin_isSmallScreen(800);
    },
    // changes the source of the video
    sourceIt () {
      let tmpSrc = null;
      if (this.mobileIsActive == true) {
        tmpSrc = this.altMobileVideoUrl ? this.altMobileVideoUrl : this.altVideoUrl;
      } else {
        tmpSrc = this.altVideoUrl ? this.altVideoUrl : this.altMobileVideoUrl;
      }
      this.playerOptions.poster = this.altSnapshotUrl;
      this.playerOptions.sources = [{
        type: "video/mp4",
        src: tmpSrc
      }]; 
      window.setTimeout(() => {
          if (this.$refs && this.$refs.videoRef && this.$refs.videoRef.player) {
            this.$refs.videoRef.player.playbackRate(2);
            this.$refs.videoRef.player.volume(0.2);
            this.$refs.videoRef.player.height(300);
          }
      }, 100);   
    },
//#endregion

//#region Modal
    // toggle the modal-element (the lightbox)
    openModal (run) {
      this.mobileIsActive = true;
      if (run) {
        this.runId = run.id;
        this.altSnapshotUrl = run.snapshotUrl;
        this.altVideoUrl = run.videoUrl;
        this.altMobileVideoUrl = run.mobileVideoUrl;
        this.raceStarted = run.raceStarted;
        this.raceFinished = run.raceFinished;
        this.objectCaught = run.objectCaught;
        this.objectLost = run.objectLost;
        this.timingMode = run.timingMode;
        this.virtualStopwatch = run.virtualStopwatch
        this.isFaulty = run.isFaulty;
        this.timeout = run.timeout;
        this.sourceIt();
        this.runDate = run.createDate;
        this.cardNumbers = "";
        for (let index = 0; index < run.identificationIds.length; index++) {
          let element = run.identificationIds[index];
          if (index + 1 >= run.identificationIds.length) {
            this.cardNumbers += `${ element }`;
          } else {
            this.cardNumbers += `${ element }, `;
          }
        }
      }
      if (this.$refs.skimovieModal && ((this.altVideoUrl && this.altVideoUrl.length > 0) || (this.altMobileVideoUrl && this.altMobileVideoUrl.length > 0))) {
        this.$refs.skimovieModal.open();
      } else {
        this.$refs.detailsModal.open();
      }
    },
    closeModal () {
      this.mobileIsActive = true;
      this.altSnapshotUrl = null;
      this.altVideoUrl = null;
      this.altMobileVideoUrl = null;
      this.raceStarted = null;
      this.raceFinishe = null;
      this.objectCaught = null;
      this.objectLost = null;
      this.timingMode = null;
      this.virtualStopwatch = null;
      this.isFaulty = null;
      this.timeout = null;
      if (this.$refs.sourceRef) {
        let source = this.$refs.sourceRef;
        source.setAttribute('src', '');
      }
      this.playerOptions.sources = [null];
      this.playerOptions.poster = null;
    },
//#endregion

//#region API-calls
    loadSessions (payload) {
      if (payload == null || this.useAlternativeFilter == true) {
        payload = {
          limit: 100,
          dates: [this.useAlternativeFilter == true ? null : this.filter.startDate, this.useAlternativeFilter == true ? null : this.filter.endDate]
        }
      }
      this.loading = true;
      this.axios.all([
        this.axios.get(`/Media/GetSkimoviesFromSpots/${ this.installationId }/${ this.dateTime_isoString(payload.dates[0]) }/${ this.dateTime_isoString(payload.dates[1]) }/${ payload.limit }`),
        this.axios.get(`/Media/GetSkiSchoolModeSkimoviesFromSpots/${ this.installationId }/${ this.dateTime_isoString(payload.dates[0]) }/${ this.dateTime_isoString(payload.dates[1]) }/${ payload.limit }`)
      ])
      .then(
        this.axios.spread((runResponse, schoolResponse) => {
          if(runResponse != null && runResponse.data != null && runResponse.data.length > 0) {
            let grouped = runResponse.data.reduce((h, obj) => Object.assign(h, { [obj.spotId]:( h[obj.spotId] || [] ).concat(obj) }), {})
            this.runs = Object.entries(grouped);
          }

          if(schoolResponse != null && schoolResponse.data != null && schoolResponse.data.length > 0) {
            let grouped = schoolResponse.data.reduce((h, obj) => Object.assign(h, { [obj.spotId]:( h[obj.spotId] || [] ).concat(obj) }), {})
            this.schoolRuns = Object.entries(grouped);
          }

          return;            
        })
      )
      .finally(() => {
        this.loading = false;
        window.setTimeout(() => {
          this.preparing = false;
        }, 500);
      });
    }
//#endregion
  }
}
</script>

<style>
.video-js {
  width: 100%;
}
.skimovie .sweet-modal {
  max-width: 100%;
  width: 50%;
}
@media only screen and (max-width: 600px) {
.skimovie .sweet-modal {
  max-width: 100%;
  width: 100%;
}
}
.vjs-big-play-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
video {
  max-width: 50vw !important;
}
.video-player video {
  max-width: 100% !important;
  width: 100% !important;
  background-color: #cccccc !important;
}
</style>
